import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  methods: {
    educationsParser(educations = []) {
      return educations?.map((education) => {
        const educationLevel = education.level ? education.level.toUpperCase() + ' - ' : ''
        if (education.university === '0') {
          return `${educationLevel} ${education.otherUniversity}`.trim()
        }
        return `${educationLevel} ${this.getUniversityName(education.university)}`.trim()
      })
    },
    chatActiveHoursParser(chatActiveHours = []) {
      return chatActiveHours?.map((chatActiveHour) => {
        return `${chatActiveHour.startHour} - ${chatActiveHour.endHour}`
      })
    },
    serviceTimesParser(serviceTimes = []) {
      return serviceTimes?.map((serviceTime) => {
        return `${serviceTime.title}: ${serviceTime.value}`
      })
    },
    workExperiencesParser(workExperiences = []) {
      return workExperiences?.map((workExperience) => {
        return {
          company: workExperience.companyName,
          position: `${workExperience.position} (${workExperience?.startYear || 'xxxx'}-${workExperience?.endYear || 'xxxx'})`,
        }
      })
    },
    getUniversityName(id) {
      if (isDataEmpty(this.universities)) return ''
      let a = this.universities?.find((dt) => dt.id.toString() === id)
      return a?.name || ''
    },
    getFullnameAndTitle(name = '-', educations = []) {
      const title = educations?.map((v) => v.title)
      return `${name} ${title ? title.join(', ') : ''}`.trim()
    },
    isHasRemainingQuota(quota) {
      return quota > 0
    },
  },
}
