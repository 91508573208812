<template>
  <!-- WARN: [hacky way] use to enabling scrolling on IOS modal - block-scroll-on-mount=false -->
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    is-centered
    size="xs"
    :initial-focus-ref="() => $refs.initialRef"
    :final-focus-ref="() => {}"
    :block-scroll-on-mount="false"
  >
    <c-modal-content
      ref="content"
      position="relative"
      width="100%"
      :max-width="['90%', '1000px']"
      :max-height="['85vh', '90vh']"
      :border-radius="['12px']"
      overflow-y="auto"
      overflow-x="hidden"
      margin="0"
    >
      <c-box
        position="absolute"
        top="8px"
        right="8px"
        width="35px"
        cursor="pointer"
        z-index="2"
        @click="onClose"
      >
        <c-image
          :src="require('@/assets/ic-cancel-red.svg')"
          width="35px"
          height="35px"
          position="fixed"
        />
      </c-box>
      <c-modal-body
        width="100%"
        :padding="['16px', '16px 32px']"
        margin="auto"
      >
        <c-text
          font-family="Roboto"
          :font-size="['18px', '20px']"
          color="#111"
          font-weight="700"
          margin-bottom="12px"
          text-align="center"
        >
          Detail Ahli Gizi
        </c-text>
        <c-flex
          width="100%"
          :flex-direction="['column', 'row']"
          justify-content="space-between"
          align-items="center"
          :margin-bottom="['0px', '1rem']"
        >
          <c-flex
            :width="['100%', '400px']"
            position="relative"
            flex-shrink="0"
            :margin-bottom="['16px', '0']"
          >
            <c-flex
              v-show="!isShowSkeleton"
              position="absolute"
              top="8px"
              right="20px"
              align-items="center"
            >
              <c-image
                :src="require('@/assets/icon-star.svg')"
                alt="icon"
                display="inline-block"
                vertical-align="middle"
                margin-right="4px"
                :width="['15px', '18px']"
              />
              <c-text
                font-family="Roboto"
                :font-size="['14px', '16px']"
                color="#fff"
              >
                {{ rating }}/5
              </c-text>
            </c-flex>
            <c-box
              v-show="isShowSkeleton"
              width="100%"
              :height="['170px', '230px']"
              border-radius="20px"
              overflow="hidden"
              position="relative"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-image
              v-show="!isShowSkeleton"
              border-radius="20px"
              :src="getPhotoNutritionistAlt(photoUrl)"
              width="100%"
              :height="['170px', '230px']"
              object-fit="cover"
            />
          </c-flex>
          <c-box
            flex-grow="1"
            width="100%"
            :margin-left="['0px', '24px']"
            :margin-bottom="['1rem', '0px']"
            :text-align="['center', 'left']"
            border-bottom="1px solid #C4C4C4"
          >
            <c-box
              v-show="isShowSkeleton"
              width="60%"
              :height="['20px', '30px']"
              margin-bottom="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-text
              v-show="!isShowSkeleton"
              font-family="Roboto"
              :font-size="['18px', '20px']"
              font-weight="700"
              color="#111"
              margin-bottom="4px"
            >
              {{ fullname }}
            </c-text>
            <c-box
              v-show="isShowSkeleton"
              width="30%"
              :height="['10px', '20px']"
              margin-bottom="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-text
              v-show="!isShowSkeleton"
              font-amily="Roboto"
              :font-size="['14px', '16px']"
              font-weight="400"
              color="#008C81"
              margin-bottom="8px"
            >
              {{ getSpecialization }}
            </c-text>
            <c-box
              v-show="isShowSkeleton"
              width="60%"
              :height="['10px', '20px']"
              margin-bottom="4px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-text
              v-show="!isShowSkeleton"
              font-amily="Roboto"
              :font-size="['12px', '14px']"
              font-weight="400"
              margin-bottom="4px"
              color="#333"
            >
              Jam Layanan: {{ serviceHour }}
            </c-text>

            <c-box
              v-show="isShowSkeleton"
              width="60%"
              :height="['10px', '20px']"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-box
              v-if="educations.length > 0 && !isShowSkeleton"
              margin-bottom="8px"
            >
              <c-text
                v-for="education in educations"
                :key="education"
                font-family="Roboto"
                :font-size="['12px', '14px']"
                font-weight="400"
                color="#333"
                text-transform="capitalize"
              >
                {{ education }}
              </c-text>
            </c-box>
            <c-box>
              <c-text
                font-family="Roboto"
                :font-size="['14px', '18px']"
                font-weight="500"
                color="#111"
              >
                Nomor Surat Tanda Registrasi (STR)
              </c-text>
              <c-box
                v-show="isShowSkeleton"
                width="60%"
                :height="['15px', '25px']"
                margin-bottom="1rem"
              >
                <vue-skeleton-loader
                  type="rect"
                  width="100%"
                  height="100%"
                  rounded
                  animation="fade"
                />
              </c-box>
              <c-text
                v-show="!isShowSkeleton"
                font-family="Roboto"
                :font-size="['14px', '18px']"
                font-weight="500"
                color="#111"
                margin-bottom="1rem"
              >
                {{ strNumber }}
              </c-text>
            </c-box>
          </c-box>
        </c-flex>
        <c-flex
          width="100%"
          :flex-direction="['column', 'row']"
          justify-content="center"
          align-items="start"
          :margin-bottom="['0px', '1rem']"
        >
          <c-box
            :width="['100%', '50%']"
            :px="['1rem', '1.5rem']"
            :margin-bottom="['1rem', '0']"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="#111"
              margin-bottom="4px"
            >
              Jadwal layanan:
            </c-text>
            <c-box
              v-for="(_, idx) in 3"
              v-show="isShowSkeleton"
              :key="idx"
              width="60%"
              :height="['10px', '20px']"
              padding-top="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-list
              v-show="!isShowSkeleton"
              style-type="disc"
              color="#555"
              :font-size="['14px', '16px']"
              font-family="Roboto"
              font-weight="400"
            >
              <c-list-item
                v-for="serviceTime in serviceTimes"
                :key="serviceTime"
              >
                {{ serviceTime }}
              </c-list-item>
              <c-list-item v-show="isDataEmpty(serviceTimes)">
                -
              </c-list-item>
            </c-list>
          </c-box>
          <c-box
            :width="['100%', '50%']"
            :px="['1rem', '1.5rem']"
            :margin-bottom="['1rem', '0']"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="#111"
              margin-bottom="4px"
            >
              Jam aktif chat
            </c-text>
            <c-box
              v-for="(_, idx) in 3"
              v-show="isShowSkeleton"
              :key="idx"
              width="60%"
              :height="['10px', '20px']"
              padding-top="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-list
              v-show="!isShowSkeleton"
              style-type="disc"
              color="#555"
              :font-size="['14px', '16px']"
              font-family="Roboto"
              font-weight="400"
            >
              <c-list-item
                v-for="chatActiveHour in chatActiveHours"
                :key="chatActiveHour"
              >
                {{ chatActiveHour }}
              </c-list-item>
              <c-list-item v-show="isDataEmpty(chatActiveHours)">
                -
              </c-list-item>
            </c-list>
          </c-box>
        </c-flex>
        <c-flex
          width="100%"
          :flex-direction="['column', 'row']"
          justify-content="center"
          align-items="start"
          :margin-bottom="['0px', '1rem']"
        >
          <c-box
            :width="['100%', '50%']"
            :px="['1rem', '1.5rem']"
            :margin-bottom="['1rem', '0']"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="#111"
              margin-bottom="4px"
            >
              Masalah yang dikuasai:
            </c-text>
            <c-box
              v-for="(_, idx) in 3"
              v-show="isShowSkeleton"
              :key="idx"
              width="60%"
              :height="['10px', '20px']"
              padding-top="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-list
              v-show="!isShowSkeleton"
              style-type="disc"
              color="#555"
              :font-size="['14px', '16px']"
              font-family="Roboto"
              font-weight="400"
            >
              <c-list-item
                v-for="item in problemHandles"
                :key="item"
              >
                {{ item }}
              </c-list-item>
              <c-list-item v-show="isDataEmpty(problemHandles)">
                -
              </c-list-item>
            </c-list>
          </c-box>
          <c-box
            :width="['100%', '50%']"
            :px="['1rem', '1.5rem']"
            :margin-bottom="['1rem', '0']"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="#111"
              margin-bottom="4px"
            >
              Usia klien yang ditangani:
            </c-text>
            <c-box
              v-for="(_, idx) in 3"
              v-show="isShowSkeleton"
              :key="idx"
              width="60%"
              :height="['10px', '20px']"
              padding-top="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-list
              v-show="!isShowSkeleton"
              style-type="disc"
              color="#555"
              :font-size="['14px', '16px']"
              font-family="Roboto"
              font-weight="400"
            >
              <c-list-item
                v-for="clientAgeHandled in clientAgeHandles"
                :key="clientAgeHandled"
              >
                {{ clientAgeHandled }}
              </c-list-item>
              <c-list-item v-show="isDataEmpty(clientAgeHandles)">
                -
              </c-list-item>
            </c-list>
          </c-box>
        </c-flex>
        <c-flex
          width="100%"
          :flex-direction="['column', 'row']"
          justify-content="center"
          align-items="start"
          :margin-bottom="['0px', '1rem']"
        >
          <c-box
            :width="['100%', '50%']"
            :px="['1rem', '1.5rem']"
            :margin-bottom="['1rem', '0']"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="#111"
              margin-bottom="4px"
            >
              Bahasa yang digunakan:
            </c-text>
            <c-box
              v-for="(_, idx) in 3"
              v-show="isShowSkeleton"
              :key="idx"
              width="60%"
              :height="['10px', '20px']"
              padding-top="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-list
              v-show="!isShowSkeleton"
              style-type="disc"
              color="#555"
              :font-size="['14px', '16px']"
              font-family="Roboto"
              font-weight="400"
            >
              <c-list-item
                v-for="language in languages"
                :key="language"
              >
                Bahasa {{ language }}
              </c-list-item>
              <c-list-item v-show="isDataEmpty(languages)">
                -
              </c-list-item>
            </c-list>
          </c-box>
          <c-box
            :width="['100%', '50%']"
            :px="['1rem', '1.5rem']"
            :margin-bottom="['1rem', '0']"
          >
            <c-text
              font-family="Roboto"
              :font-size="['14px', '18px']"
              font-weight="500"
              color="#111"
              margin-bottom="4px"
            >
              Pengalaman:
            </c-text>
            <c-box
              v-for="(_, idx) in 3"
              v-show="isShowSkeleton"
              :key="idx"
              width="60%"
              :height="['10px', '20px']"
              padding-top="8px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-list
              v-show="!isShowSkeleton"
              style-type="disc"
              color="#555"
              :font-size="['14px', '16px']"
              font-family="Roboto"
              font-weight="400"
            >
              <c-list-item
                v-for="workExperience in workExperiences"
                :key="workExperience.company || '' + workExperience.position || '' "
              >
                <c-box
                  display="inline-table"
                >
                  <BaseText
                    size-mobile="16px"
                    size-desktop="18px"
                    color="#111"
                  >
                    {{ workExperience.company || "-" }}
                  </BaseText>
                  <BaseText
                    size-mobile="14px-2"
                    size-desktop="16px"
                  >
                    {{ workExperience.position || "-" }}
                  </BaseText>
                </c-box>
              </c-list-item>
              <c-list-item v-show="isDataEmpty(workExperiences)">
                Belum ada pengalaman
              </c-list-item>
            </c-list>
          </c-box>
        </c-flex>
        <c-box
          v-show="withButtonChooseNutritionist"
          display="flex"
          justify-content="center"
        >
          <BaseButton
            margin-top="16px"
            min-height="50px"
            border-radius="1000px"
            :width="['100%', '500px']"
            :disabled="isDisabledButtonChooseNutritionist"
            @click="onChooseNutritionist"
          >
            {{ buttonSubmitText }}
          </BaseButton>
        </c-box>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import generalMixin from '@/utils/general-mixins'
import { isDataEmpty } from '@/utils/is-data-empty'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'ModalNutritionits',
  components: { BaseButton, BaseText },
  mixins: [
    generalMixin,
  ],
  props: {
    isShowSkeleton: {
      type: Boolean,
      // default: true,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    fullname: {
      type: String,
      default: '-',
    },
    photoUrl: {
      type: String,
      default: '',
    },
    strNumber: {
      type: String,
      default: '-',
    },
    languages: {
      type: Array,
      default: () => [],
    },
    rating: {
      type: [Number, String],
      default: '-',
    },
    problemHandles: {
      type: Array,
      default: () => [],
    },
    clientAgeHandles: {
      type: Array,
      default: () => [],
    },
    specializations: {
      type: [Array, String],
      default: () => [],
    },
    educations: {
      type: Array,
      default: () => [],
    },
    workExperiences: {
      type: Array,
      default: () => [],
    },
    serviceHour: {
      type: String,
      default: '-',
    },
    chatActiveHours: {
      type: Array,
      default: () => [],
    },
    serviceTimes: {
      type: Array,
      default: () => [],
    },
    withButtonChooseNutritionist: {
      type: Boolean,
      default: false,
    },
    isDisabledButtonChooseNutritionist: {
      type: Boolean,
      default: false,
    },
    buttonSubmitText: {
      type: String,
      default: 'Pilih Ahli Gizi',
    },
  },
  computed: {
    getSpecialization() {
      switch (typeof this.specializations) {
        case 'string':
          return this.specializations
        case 'object':
          if (this.specializations?.length > 0) {
            return this.specializations.join(', ')
          }
          return '-'
        default:
          return '-'
      }
    },
  },
  methods: {
    isDataEmpty,
    onClose() {
      this.$emit('on-close')
    },
    onChooseNutritionist() {
      this.$emit('on-choose-nutritionist')
    },
  },
}
</script>
