var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "is-centered": "",
      "size": "xs",
      "initial-focus-ref": function initialFocusRef() {
        return _vm.$refs.initialRef;
      },
      "final-focus-ref": function finalFocusRef() {},
      "block-scroll-on-mount": false
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "position": "relative",
      "width": "100%",
      "max-width": ['90%', '1000px'],
      "max-height": ['85vh', '90vh'],
      "border-radius": ['12px'],
      "overflow-y": "auto",
      "overflow-x": "hidden",
      "margin": "0"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "absolute",
      "top": "8px",
      "right": "8px",
      "width": "35px",
      "cursor": "pointer",
      "z-index": "2"
    },
    on: {
      "click": _vm.onClose
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-cancel-red.svg'),
      "width": "35px",
      "height": "35px",
      "position": "fixed"
    }
  })], 1), _c('c-modal-body', {
    attrs: {
      "width": "100%",
      "padding": ['16px', '16px 32px'],
      "margin": "auto"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "color": "#111",
      "font-weight": "700",
      "margin-bottom": "12px",
      "text-align": "center"
    }
  }, [_vm._v(" Detail Ahli Gizi ")]), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": ['column', 'row'],
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": ['0px', '1rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "width": ['100%', '400px'],
      "position": "relative",
      "flex-shrink": "0",
      "margin-bottom": ['16px', '0']
    }
  }, [_c('c-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "position": "absolute",
      "top": "8px",
      "right": "20px",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-star.svg'),
      "alt": "icon",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-right": "4px",
      "width": ['15px', '18px']
    }
  }), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#fff"
    }
  }, [_vm._v(" " + _vm._s(_vm.rating) + "/5 ")])], 1), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowSkeleton,
      expression: "isShowSkeleton"
    }],
    attrs: {
      "width": "100%",
      "height": ['170px', '230px'],
      "border-radius": "20px",
      "overflow": "hidden",
      "position": "relative"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-image', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "border-radius": "20px",
      "src": _vm.getPhotoNutritionistAlt(_vm.photoUrl),
      "width": "100%",
      "height": ['170px', '230px'],
      "object-fit": "cover"
    }
  })], 1), _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "width": "100%",
      "margin-left": ['0px', '24px'],
      "margin-bottom": ['1rem', '0px'],
      "text-align": ['center', 'left'],
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowSkeleton,
      expression: "isShowSkeleton"
    }],
    attrs: {
      "width": "60%",
      "height": ['20px', '30px'],
      "margin-bottom": "8px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "font-weight": "700",
      "color": "#111",
      "margin-bottom": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.fullname) + " ")]), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowSkeleton,
      expression: "isShowSkeleton"
    }],
    attrs: {
      "width": "30%",
      "height": ['10px', '20px'],
      "margin-bottom": "8px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "font-amily": "Roboto",
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "#008C81",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getSpecialization) + " ")]), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowSkeleton,
      expression: "isShowSkeleton"
    }],
    attrs: {
      "width": "60%",
      "height": ['10px', '20px'],
      "margin-bottom": "4px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "font-amily": "Roboto",
      "font-size": ['12px', '14px'],
      "font-weight": "400",
      "margin-bottom": "4px",
      "color": "#333"
    }
  }, [_vm._v(" Jam Layanan: " + _vm._s(_vm.serviceHour) + " ")]), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowSkeleton,
      expression: "isShowSkeleton"
    }],
    attrs: {
      "width": "60%",
      "height": ['10px', '20px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _vm.educations.length > 0 && !_vm.isShowSkeleton ? _c('c-box', {
    attrs: {
      "margin-bottom": "8px"
    }
  }, _vm._l(_vm.educations, function (education) {
    return _c('c-text', {
      key: education,
      attrs: {
        "font-family": "Roboto",
        "font-size": ['12px', '14px'],
        "font-weight": "400",
        "color": "#333",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(education) + " ")]);
  }), 1) : _vm._e(), _c('c-box', [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111"
    }
  }, [_vm._v(" Nomor Surat Tanda Registrasi (STR) ")]), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowSkeleton,
      expression: "isShowSkeleton"
    }],
    attrs: {
      "width": "60%",
      "height": ['15px', '25px'],
      "margin-bottom": "1rem"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111",
      "margin-bottom": "1rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.strNumber) + " ")])], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": ['column', 'row'],
      "justify-content": "center",
      "align-items": "start",
      "margin-bottom": ['0px', '1rem']
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['100%', '50%'],
      "px": ['1rem', '1.5rem'],
      "margin-bottom": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111",
      "margin-bottom": "4px"
    }
  }, [_vm._v(" Jadwal layanan: ")]), _vm._l(3, function (_, idx) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowSkeleton,
        expression: "isShowSkeleton"
      }],
      key: idx,
      attrs: {
        "width": "60%",
        "height": ['10px', '20px'],
        "padding-top": "8px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1);
  }), _c('c-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "style-type": "disc",
      "color": "#555",
      "font-size": ['14px', '16px'],
      "font-family": "Roboto",
      "font-weight": "400"
    }
  }, [_vm._l(_vm.serviceTimes, function (serviceTime) {
    return _c('c-list-item', {
      key: serviceTime
    }, [_vm._v(" " + _vm._s(serviceTime) + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.serviceTimes),
      expression: "isDataEmpty(serviceTimes)"
    }]
  }, [_vm._v(" - ")])], 2)], 2), _c('c-box', {
    attrs: {
      "width": ['100%', '50%'],
      "px": ['1rem', '1.5rem'],
      "margin-bottom": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111",
      "margin-bottom": "4px"
    }
  }, [_vm._v(" Jam aktif chat ")]), _vm._l(3, function (_, idx) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowSkeleton,
        expression: "isShowSkeleton"
      }],
      key: idx,
      attrs: {
        "width": "60%",
        "height": ['10px', '20px'],
        "padding-top": "8px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1);
  }), _c('c-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "style-type": "disc",
      "color": "#555",
      "font-size": ['14px', '16px'],
      "font-family": "Roboto",
      "font-weight": "400"
    }
  }, [_vm._l(_vm.chatActiveHours, function (chatActiveHour) {
    return _c('c-list-item', {
      key: chatActiveHour
    }, [_vm._v(" " + _vm._s(chatActiveHour) + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.chatActiveHours),
      expression: "isDataEmpty(chatActiveHours)"
    }]
  }, [_vm._v(" - ")])], 2)], 2)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": ['column', 'row'],
      "justify-content": "center",
      "align-items": "start",
      "margin-bottom": ['0px', '1rem']
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['100%', '50%'],
      "px": ['1rem', '1.5rem'],
      "margin-bottom": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111",
      "margin-bottom": "4px"
    }
  }, [_vm._v(" Masalah yang dikuasai: ")]), _vm._l(3, function (_, idx) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowSkeleton,
        expression: "isShowSkeleton"
      }],
      key: idx,
      attrs: {
        "width": "60%",
        "height": ['10px', '20px'],
        "padding-top": "8px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1);
  }), _c('c-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "style-type": "disc",
      "color": "#555",
      "font-size": ['14px', '16px'],
      "font-family": "Roboto",
      "font-weight": "400"
    }
  }, [_vm._l(_vm.problemHandles, function (item) {
    return _c('c-list-item', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.problemHandles),
      expression: "isDataEmpty(problemHandles)"
    }]
  }, [_vm._v(" - ")])], 2)], 2), _c('c-box', {
    attrs: {
      "width": ['100%', '50%'],
      "px": ['1rem', '1.5rem'],
      "margin-bottom": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111",
      "margin-bottom": "4px"
    }
  }, [_vm._v(" Usia klien yang ditangani: ")]), _vm._l(3, function (_, idx) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowSkeleton,
        expression: "isShowSkeleton"
      }],
      key: idx,
      attrs: {
        "width": "60%",
        "height": ['10px', '20px'],
        "padding-top": "8px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1);
  }), _c('c-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "style-type": "disc",
      "color": "#555",
      "font-size": ['14px', '16px'],
      "font-family": "Roboto",
      "font-weight": "400"
    }
  }, [_vm._l(_vm.clientAgeHandles, function (clientAgeHandled) {
    return _c('c-list-item', {
      key: clientAgeHandled
    }, [_vm._v(" " + _vm._s(clientAgeHandled) + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.clientAgeHandles),
      expression: "isDataEmpty(clientAgeHandles)"
    }]
  }, [_vm._v(" - ")])], 2)], 2)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": ['column', 'row'],
      "justify-content": "center",
      "align-items": "start",
      "margin-bottom": ['0px', '1rem']
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['100%', '50%'],
      "px": ['1rem', '1.5rem'],
      "margin-bottom": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111",
      "margin-bottom": "4px"
    }
  }, [_vm._v(" Bahasa yang digunakan: ")]), _vm._l(3, function (_, idx) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowSkeleton,
        expression: "isShowSkeleton"
      }],
      key: idx,
      attrs: {
        "width": "60%",
        "height": ['10px', '20px'],
        "padding-top": "8px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1);
  }), _c('c-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "style-type": "disc",
      "color": "#555",
      "font-size": ['14px', '16px'],
      "font-family": "Roboto",
      "font-weight": "400"
    }
  }, [_vm._l(_vm.languages, function (language) {
    return _c('c-list-item', {
      key: language
    }, [_vm._v(" Bahasa " + _vm._s(language) + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.languages),
      expression: "isDataEmpty(languages)"
    }]
  }, [_vm._v(" - ")])], 2)], 2), _c('c-box', {
    attrs: {
      "width": ['100%', '50%'],
      "px": ['1rem', '1.5rem'],
      "margin-bottom": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#111",
      "margin-bottom": "4px"
    }
  }, [_vm._v(" Pengalaman: ")]), _vm._l(3, function (_, idx) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isShowSkeleton,
        expression: "isShowSkeleton"
      }],
      key: idx,
      attrs: {
        "width": "60%",
        "height": ['10px', '20px'],
        "padding-top": "8px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1);
  }), _c('c-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isShowSkeleton,
      expression: "!isShowSkeleton"
    }],
    attrs: {
      "style-type": "disc",
      "color": "#555",
      "font-size": ['14px', '16px'],
      "font-family": "Roboto",
      "font-weight": "400"
    }
  }, [_vm._l(_vm.workExperiences, function (workExperience) {
    return _c('c-list-item', {
      key: workExperience.company || '' + workExperience.position || ''
    }, [_c('c-box', {
      attrs: {
        "display": "inline-table"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "16px",
        "size-desktop": "18px",
        "color": "#111"
      }
    }, [_vm._v(" " + _vm._s(workExperience.company || "-") + " ")]), _c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "16px"
      }
    }, [_vm._v(" " + _vm._s(workExperience.position || "-") + " ")])], 1)], 1);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.workExperiences),
      expression: "isDataEmpty(workExperiences)"
    }]
  }, [_vm._v(" Belum ada pengalaman ")])], 2)], 2)], 1), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.withButtonChooseNutritionist,
      expression: "withButtonChooseNutritionist"
    }],
    attrs: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('BaseButton', {
    attrs: {
      "margin-top": "16px",
      "min-height": "50px",
      "border-radius": "1000px",
      "width": ['100%', '500px'],
      "disabled": _vm.isDisabledButtonChooseNutritionist
    },
    on: {
      "click": _vm.onChooseNutritionist
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonSubmitText) + " ")])], 1)], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }